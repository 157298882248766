<template>
  <v-select
    class="driver-select"
    :options="drivers"
    label="name"
    :reduce="driver => driver.id"
    v-model="trip.driver"
    @input="driverAssign"
    :disabled="trip.status == 'FAIL' || trip.status == 'COMPLETED'"
  ></v-select>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: { vSelect },
  props: {
    drivers: {
      type: Array,
      default: () => []
    },
    trip: {
      type: Object,
      default: () => {}
    }
  },

  setup(props, { emit }) {
    const driverAssign = id => {
      emit("driver-changed", props.trip.id, id);
    };

    return {
      driverAssign
    };
  }
};
</script>

<style lang="scss">
// FOR DROPDOWN LONG TEXT
// .driver-select {
//   .vs__selected-options {
//     flex-wrap: nowrap !important;
//     max-width: calc(100% - 41px) !important;
//   }

//   .vs__selected {
//     display: block;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     max-width: 100%;
//     overflow: hidden;
//   }

//   .vs__search {
//     position: absolute;
//   }

//   .vs--open .vs__search {
//     position: static;
//   }

//   .vs__dropdown-option {
//     white-space: normal;
//   }
// }
</style>
