import ApiService from "@/core/services/api.service";

const DRIVER_END_POINT = "/drivers";

const getAllDriver = () => ApiService.get(`${DRIVER_END_POINT}`);

const getDriver = id => ApiService.get(`${DRIVER_END_POINT}`, id);

const createDriver = param => ApiService.post(`${DRIVER_END_POINT}`, param);

const updateDriver = (id, param) => ApiService.update(`${DRIVER_END_POINT}`, id, param);

const lockDriver = id => ApiService.put(`${DRIVER_END_POINT}/${id}/lock`);
const unlockDriver = id => ApiService.put(`${DRIVER_END_POINT}/${id}/unlock`);

export { getAllDriver, getDriver, createDriver, updateDriver, lockDriver, unlockDriver };
