<template>
  <b-modal
    ref="dispatchsummary"
    id="full-screen-modal"
    hide-footer
    no-fade
    size="huge"
  >
    <div class="flex-row-fluid ml-lg-8">
      <!--begin::Row-->
      <div class="row" v-for="(obj, pos) in flattenedTrip" :key="pos">
        <div class="col-md-12">
          <h3>{{ pos }}</h3>
          <ul>
            <li v-for="trip in obj" :key="trip.id">
              {{ trip.address }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { reactive, onMounted, computed } from "@vue/composition-api";

export default {
  name: "DispatchSummaryModal",
  props: {
    jobs: Array,
    drivers: Array,
  },
  setup(props) {
    const flattenedTrip = computed(() => {
      let trips = [];
      props.jobs.forEach((element) => {
        element.trips.forEach((t) => {
          if (t.status != "COMPLETED" && t.status != "FAIL") trips.push(t);
        });
      });

      const groupedDriver = trips.reduce((acc, value) => {
        // Group initialization
        if (value.driver != null) {
          let driver = props.drivers.find((d) => d.id == value.driver);
          console.log(driver);
          if (!acc[driver.name]) {
            acc[driver.name] = [];
          }

          acc[driver.name].push(value);
          // Grouping
        }

        return acc;
      }, {});

      const res = Object.entries(groupedDriver).map(([label, options]) => ({
        label,
        options,
      }));

      return groupedDriver;
    });

    return {
      flattenedTrip,
    };
  },
};
</script>
