<!---->
<template>
  <b-modal
    ref="jobdetails"
    id="full-screen-modal"
    hide-footer
    no-fade
    @show="onModalShow"
    size="huge"
  >
    <div class="flex-row-fluid ml-lg-8" v-if="jobdetails">
      <!--begin::Row-->
      <div class="row">
        <div class="col-lg-6">
          <!--begin::List Widget 14-->
          <div class="card card-custom card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0">
              <h3 class="card-title font-weight-bolder text-dark">
                Job Details
              </h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-2">
              <div class="row mb-6">
                <!--begin::Info-->
                <div class="col-6 col-md-4">
                  <div class="mb-8 d-flex flex-column">
                    <span class="text-dark font-weight-bold mb-4">Job No.</span>
                    <span class="text-muted font-weight-bolder font-size-lg">{{
                      jobdetails.jobid
                    }}</span>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="mb-8 d-flex flex-column">
                    <span class="text-dark font-weight-bold mb-4"
                      >Reference No.</span
                    >
                    <span class="text-muted font-weight-bolder font-size-lg">{{
                      jobdetails.referenceno
                    }}</span>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="mb-8 d-flex flex-column">
                    <span class="text-dark font-weight-bold mb-4">Status</span>
                    <span class="text-muted font-weight-bolder font-size-lg">{{
                      jobdetails.status
                    }}</span>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="mb-8 d-flex flex-column">
                    <span class="text-dark font-weight-bold mb-4">Client</span>
                    <span class="text-muted font-weight-bolder font-size-lg">{{
                      jobdetails.client.name
                    }}</span>
                  </div>
                </div>
                <!--end::Info-->
              </div>
            </div>
            <!--end::Body-->
          </div>
          <!--end::List Widget 14-->
        </div>
        <div class="col-lg-6">
          <!--begin::List Widget 10-->
          <div class="card card-custom card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0">
              <h3 class="card-title font-weight-bolder text-dark">Items</h3>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
              <!--begin::Item-->
              <div class="row mb-6">
                <!--begin::Info-->
                <div class="col-6 col-md-4">
                  <div class="mb-8 d-flex flex-column">
                    <span class="text-dark font-weight-bold mb-4"
                      >Weight (kg)</span
                    >
                    <span class="text-muted font-weight-bolder font-size-lg">{{
                      jobdetails.item_weight
                    }}</span>
                  </div>
                </div>
                <div class="col-6 col-md-4">
                  <div class="mb-8 d-flex flex-column">
                    <span class="text-dark font-weight-bold mb-4"
                      >Number of items</span
                    >
                    <span class="text-muted font-weight-bolder font-size-lg">{{
                      jobdetails.number_of_items
                    }}</span>
                  </div>
                </div>
              </div>
              <!--end::Item-->
              <!--end: Item-->
            </div>
            <!--end: Card Body-->
          </div>
          <!--end: Card-->
          <!--end: List Widget 10-->
        </div>
      </div>
      <!--end::Row-->
      <!--begin::Advance Table: Widget 7-->
      <div class="card card-custom gutter-b">
        <!--begin::Header-->
        <div class="card-header border-0 pt-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label font-weight-bolder text-dark">Trips</span>
            <span class="text-muted mt-3 font-weight-bold font-size-sm"
              >All trips related to the job</span
            >
          </h3>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body pt-2 pb-0 mt-n3">
          <div class="table-responsive">
            <table class="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th class="p-0 w-80px"></th>
                  <th class="p-0 min-w-100px">Planned</th>
                  <th class="p-0 min-w-100px"></th>
                  <th class="p-0 min-w-120px">Address</th>
                  <th class="p-0 min-w-120px">Contact</th>
                  <th class="p-0 min-w-120px">Remark</th>
                  <th class="p-0 min-w-180px">Driver</th>
                  <th class="p-0 min-w-200px">Start/End</th>
                  <th class="p-0 min-w-180px">Trip note</th>
                  <th class="p-0 min-w-200px"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="trip in jobdetails.trips" :key="trip.id">
                  <td class="pl-0">
                    <span
                      class="text-muted font-weight-bolder mb-1 font-size-lg"
                      >{{ trip.type }}</span
                    >
                  </td>
                  <td class="pl-0">
                    <span
                      class="text-dark font-weight-bolder mb-1 font-size-lg"
                      >{{ trip.date }}</span
                    >
                    <span
                      class="text-muted font-weight-bold d-block font-size-xs"
                      >{{ trip.from_time }} - {{ trip.to_time }}</span
                    >
                  </td>
                  <td class="pl-0">
                    <span class="text-dark font-weight-bolder mb-1">{{
                      trip.company
                    }}</span>
                  </td>
                  <td class="pl-0">
                    <span
                      class="text-dark font-weight-bolder mb-1 font-size-lg"
                      >{{ trip.address }}</span
                    >
                    <span class="text-muted font-weight-bold d-block">{{
                      trip.unit_no
                    }}</span>
                  </td>
                  <td class="pl-0">
                    <span class="text-dark font-weight-bolder mb-1">{{
                      trip.contact_person
                    }}</span>
                    <span class="text-dark font-weight-bolder mb-1 d-block">{{
                      trip.contact_number
                    }}</span>
                  </td>
                  <td class="pl-0">
                    <span class="text-danger font-weight-bolder mb-1">{{
                      trip.remark
                    }}</span>
                  </td>
                  <td class="pl-0">
                    <template v-if="trip.driver">
                      <span
                        class="
                          text-dark-75
                          font-weight-bolder
                          d-block
                          font-size-lg
                        "
                        >{{ trip.driver.name }}</span
                      >
                      <span class="text-muted font-weight-bold">{{
                        trip.driver.vehicle_no
                      }}</span>
                    </template>
                    <template v-else>
                      <span class="text-mute d-block font-size-sm"
                        >No driver assigned yet</span
                      >
                    </template>
                  </td>
                  <td class="pl-0">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-sm
                      "
                    >
                      {{
                        trip.start_on
                          ? formatDateTime(trip.start_on).toLocaleString()
                          : "no start date"
                      }}
                    </span>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-sm
                      "
                    >
                      {{
                        trip.end_on
                          ? formatDateTime(trip.end_on).toLocaleString()
                          : "no end date"
                      }}
                    </span>
                  </td>
                  <td class="pl-0">
                    <span class="text-dark-75 font-weight-bolder">{{
                      trip.note
                    }}</span>
                  </td>
                  <td class="pl-0">
                    <div
                      class="d-flex justify-content-center flex-lg-fill my-1"
                    >
                      <div
                        class="symbol-group symbol-hover"
                        v-for="attachment in trip.attachments"
                        :key="attachment.id"
                      >
                        <a
                          :href="attachment.file_path"
                          target="_blank"
                          class="symbol symbol-30 symbol-circle"
                        >
                          <img
                            :alt="attachment.file_name"
                            :src="attachment.file_path"
                          />
                        </a>
                      </div>
                    </div>
                  </td>
                  <td class="text-right">
                    <span
                      class="label label-lg label-inline"
                      :class="getColorByTripStatus(trip.status)"
                      >{{ trip.status }}</span
                    >
                  </td>
                  <td class="pr-0 text-right"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Body-->
      </div>
      <!--end::Advance Table Widget 7-->
    </div>
  </b-modal>
</template>

<script>
import { reactive, onMounted } from "@vue/composition-api";
import { parseISO } from "date-fns";

import { getJobFullDetails } from "@/api/job.api";
import { getOmsStatus } from "@/utils/oms-status";

export default {
  name: "JobFullDetailsModal",
  props: {
    jobid: Number,
  },
  setup(props) {
    const { getColorByTripStatus } = getOmsStatus();

    const jobdetails = reactive({
      jobid: "",
      referenceno: "",
      status: "",
      client: {},
      items: [],
      trips: [],
    });
    onMounted(() => {
      // console.log("job detail modal loaded.");
    });

    const onModalShow = async () => {
      const response = await getJobFullDetails(props.jobid);
      const job = response.data;

      jobdetails.jobid = job.id;
      jobdetails.referenceno = job.reference_no;
      jobdetails.item_weight = job.item_weight;
      jobdetails.number_of_items = job.number_of_items;
      jobdetails.status = job.status;
      jobdetails.client = job.client;
      jobdetails.items = job.items;
      jobdetails.trips = job.trips;
    };

    const formatDateTime = (datetime) => parseISO(datetime);

    return {
      jobdetails,
      onModalShow,
      getColorByTripStatus,
      formatDateTime,
    };
  },
};
</script>

//
<style lang="scss">
@media (min-width: 992px) {
  .modal .modal-huge {
    max-width: 90% !important;
    width: 90% !important;
  }
}

.modal-backdrop {
  width: 100%;
}

// @media (min-width: 576px) .modal-dialog {
//   max-width: auto !important;
// }
</style>
