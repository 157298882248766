<template>
  <div class="card card-custom mb-2">
    <div class="card-body py-5">
      <div class="d-flex">
        <div class="row w-100 align-items-center">
          <div class="col-md-1">
            <a href="#" @click="openJobDetail(job.id)">{{ job.id }}</a>
            <br />
            <small>{{ job.client }}</small>
          </div>
          <div class="col-md-11">
            <template v-for="trip in job.trips">
              <div
                class="d-flex flex-xl-row flex-lg-row flex-md-row flex-column align-items-center mb-3 p-2 border-bottom"
                :key="trip.id"
              >
                <div class="col-md-1 py-2 p-md-0">{{ trip.type }}</div>
                <div class="col-md-3 py-2 p-md-0">{{ trip.address }}</div>
                <div class="col-md-3 py-2 p-md-0">{{ trip.date }} {{ trip.from_time }} - {{ trip.to_time }}</div>
                <div class="col-md-2 py-2 p-md-0 text-danger">{{ trip.remark }}</div>
                <div class="col-md-1 py-2 p-md-0">
                  <span class="label label-inline font-weight-bold" :class="getColorByTripStatus(trip.status)">
                    {{ trip.status }}
                  </span>
                </div>
                <div class="col-md-2 py-2 p-md-0">
                  <driver-select :drivers="drivers" :trip="trip" @driver-changed="driverAssign" />
                </div>

                <div class="col-md-1 py-2 p-md-0">
                  <button
                    class="btn btn-icon btn-xs btn-light-danger"
                    v-if="trip.status == 'FAIL'"
                    @click="duplicate(trip.id)"
                  >
                    <i class="flaticon2-refresh"></i>
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DriverSelect from "./DriverSelect";
import { getOmsStatus } from "@/utils/oms-status";
export default {
  name: "JobOverview",
  components: { DriverSelect },
  props: {
    drivers: {
      type: Array,
      default: () => []
    },
    job: {
      type: Object,
      default: null
    }
  },
  setup(props, { emit }) {
    const { getColorByTripStatus } = getOmsStatus();

    const driverAssign = (tripid, id) => {
      emit("driver-changed", tripid, id);
    };

    const duplicate = (tripid, id) => {
      emit("duplicate", tripid, id);
    };

    const openJobDetail = job => {
      emit("open-job-detail", job);
    };

    return {
      driverAssign,
      duplicate,
      openJobDetail,

      getColorByTripStatus
    };
  }
};
</script>

<style lang="scss">
// FOR DROPDOWN LONG TEXT
// .driver-select {
//   .vs__selected-options {
//     flex-wrap: nowrap !important;
//     max-width: calc(100% - 41px) !important;
//   }

//   .vs__selected {
//     display: block;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     max-width: 100%;
//     overflow: hidden;
//   }

//   .vs__search {
//     position: absolute;
//   }

//   .vs--open .vs__search {
//     position: static;
//   }

//   .vs__dropdown-option {
//     white-space: normal;
//   }
// }
</style>
